import React from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
} from 'react-router-dom'
import {
  LandingPage,
  HomePage,
  SingleProductsPage,
  CategoryProductsPage,
  SearchProductsPage,
  LoginPage,
  EmailVerificationPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  CreateAccountPage,
  CartMainPage,
  CheckoutDeliveryPage,
  CheckoutDeliveryPageTwo,
  AdminDashboardHomePage,
  AdminVendorsPage,
  AdminTransactionsPage,
  AdminCustomersPage,
  AdminOrdersPage,
  CheckoutDeliveryPageThree,
  AccountCheckoutPage,
  PersonalInfoCheckoutPage,
  PaymentMethodPage,
  OrderSuccessPage,
  OrderDeclinePage,
  PersonalInfoCheckoutAddressPage,
  OnSuccessSignupPage,
  AddNewPersonalAddressPage,
  EditAddressPage,
  MyOrdersBlankPage,
  AdminLoginPage,
  SingleVendorPage,
  AdminProductPage,
  ViewMyOrdersPage,
  SingleShopInfoPage,
  AdminSingleCustomersPage,
  UserManagementPage,
  SettingsPage,
  ReportPage,
  GuestCheckoutPage,
  AdminExperiencesPage,
  ExperienceOrdersPage,
  ProductCategoriesPage,
  ParentCategoriesPage,
  SubCategoriesPage,
  ColoursPage,
  GiftShopPage,
  EventCategoryPage,
  EventSubCategoryPage,
} from './pages'
import Home from './pages/homePage/Home'
import Events from './pages/events/events'
import SingleEvent from './pages/singleProductsPage/SingleEvent'
import EventSearch from './pages/events/EventSearch'
import ExperienceCheckout from './pages/experienceCheckout/experienceCheckout'
import NewSingleEvent from './pages/singleProductsPage/NewSingleEvent'

const RoutesE = () => {
  let routes = useRoutes([
    { path: '/landing', element: <LandingPage /> },
    { path: '/homepage', element: <HomePage /> },
    { path: '/', element: <Home /> },
    { path: '/kids-events', element: <Events /> },
    { path: '/single-product/:id', element: <SingleProductsPage /> },
    //New cHANGE HERE
    // { path: '/single-experience/:id', element: <SingleEvent /> },
    { path: '/single-experience/:id', element: <NewSingleEvent /> },
    {
      path: '/search-experience',
      element: <EventSearch />,
    },
    {
      path: '/product-category/:category_id',
      element: <CategoryProductsPage />,
    },
    { path: '/search/:searchTerm', element: <SearchProductsPage /> },
    { path: '/login', element: <LoginPage /> },
    { path: '/admin/login', element: <AdminLoginPage /> },
    { path: '/success-signup', element: <OnSuccessSignupPage /> },
    { path: '/verification/', element: <EmailVerificationPage /> },
    { path: '/forgot-password', element: <ForgotPasswordPage /> },
    { path: '/change-password', element: <ResetPasswordPage /> },
    { path: '/create-account', element: <CreateAccountPage /> },
    { path: '/cart-page', element: <CartMainPage /> },
    { path: '/checkout-delivery', element: <CheckoutDeliveryPage /> },
    {
      path: '/experience-checkout',
      element: <ExperienceCheckout />,
    },
    { path: '/checkout-guest', element: <GuestCheckoutPage /> },
    {
      path: '/admin/dashboard/home',
      element: <AdminDashboardHomePage type='admin' />,
    },
    {
      path: '/admin/dashboard/vendors',
      element: <AdminVendorsPage type='admin' />,
    },
    { path: '/admin/dashboard/vendors/:id', element: <SingleVendorPage /> },
    {
      path: '/admin/dashboard/transactions',
      element: <AdminTransactionsPage type='admin' />,
    },

    {
      path: '/admin/dashboard/customers',
      element: <AdminCustomersPage type='admin' />,
    },
    {
      path: '/admin/dashboard/customers/:id',
      element: <AdminSingleCustomersPage type='admin' />,
    },
    {
      path: '/admin/dashboard/products',
      element: <AdminProductPage type='admin' />,
    },
    ////Attribute pages
    {
      path: '/admin/dashboard/product-category',
      element: <ProductCategoriesPage type='admin' />,
    },
    {
      path: '/admin/dashboard/parent-category',
      element: <ParentCategoriesPage type='admin' />,
    },
    {
      path: '/admin/dashboard/parent-sub-category',
      element: <SubCategoriesPage type='admin' />,
    },
    {
      path: '/admin/dashboard/colours',
      element: <ColoursPage type='admin' />,
    },
    {
      path: '/admin/dashboard/gift-shops',
      element: <GiftShopPage type='admin' />,
    },
    {
      path: '/admin/dashboard/event-category',
      element: <EventCategoryPage type='admin' />,
    },
    {
      path: '/admin/dashboard/event-sub-category',
      element: <EventSubCategoryPage type='admin' />,
    },

    {
      path: '/admin/dashboard/experiences',
      element: <AdminExperiencesPage type='admin' />,
    },
    {
      path: '/admin/dashboard/usermanagement',
      element: <UserManagementPage type='admin' />,
    },
    {
      path: '/admin/dashboard/settings',
      element: <SettingsPage type='admin' />,
    },
    {
      path: '/admin/dashboard/report',
      element: <ReportPage type='admin' />,
    },
    {
      path: '/admin/dashboard/products/:id',
      element: <SingleShopInfoPage type='admin' />,
    },
    {
      path: '/admin/dashboard/orders',
      element: <AdminOrdersPage type='admin' />,
    },
    { path: '/account-checkout', element: <AccountCheckoutPage /> },
    { path: '/personal-checkout-page', element: <PersonalInfoCheckoutPage /> },
    {
      path: '/personal-checkout-address',
      element: <PersonalInfoCheckoutAddressPage />,
    },
    {
      path: '/personal-add-address',
      element: <AddNewPersonalAddressPage />,
    },
    {
      path: 'edit-address/:id',
      element: <EditAddressPage />,
    },
    { path: '/payment-method', element: <PaymentMethodPage /> },
    { path: '/order-success', element: <OrderSuccessPage /> },
    { path: '/order_decline', element: <OrderDeclinePage /> },
    { path: '/orders', element: <MyOrdersBlankPage /> },
    { path: '/experience-orders', element: <ExperienceOrdersPage /> },
    { path: '/view-order/:id', element: <ViewMyOrdersPage /> },
  ])

  return routes
}

const AppWrapper = () => {
  return (
    <Router>
      <RoutesE />
    </Router>
  )
}

export default AppWrapper

/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useEffect } from 'react'
import closeOrderModal from '../../../../../assets/icon/closeOrderModal.svg'
import Modal from 'react-modal'
import './newProductModal.scss'
import productTrashIcon from '../../../../../assets/icon/productTrashIcon.svg'
import { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { axiosCalls } from '../../../../../_api'
import { Toast } from '../../../../../helpers/toast'
import { hideLoader, showLoader } from '../../../../../loader'
import ModalLayout from '../../../../../components/layout/modalLayout/ModalLayout'
import Cities from '../../../../../assets/Data/cities'
import Select from 'react-select'
import DurationData from '../../../../../assets/Data/DurationData'
import ImageUrl from '../../../../../components/imageUrl/ImageUrl'
import InputToArray from '../../../../../components/InputToArray/InputToArray'
import CurrencyData from '../../../../../assets/Data/CurrencyData'
import { setSaturation } from 'polished'
const fileTypes = ['JPEG', 'PNG', 'GIF', 'JPG']

Modal.setAppElement('#root')

function NewProductModal({ handleToggleNewModal, selectedExperience }) {
  const [file, setFile] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [name, setName] = useState('')
  const [adultPrice, setAdultPrice] = useState(0)
  const [childPrice, setChildPrice] = useState(0)
  const [description, setDescription] = useState('')
  const [venue, setVenue] = useState('')
  const [socialMedia, setSocialMedia] = useState([])
  const [languages, setLanguages] = useState('')
  const [date, setDate] = useState('')
  const [additionalInfo, setAdditionalInfo] = useState([])
  const [currency, setCurrency] = useState('')
  const [duration, setDuration] = useState('')
  const [time, setTime] = useState('')
  const [mobileTicket, setMobileTicket] = useState(false)
  const [whatToExpect, setWhatToExpect] = useState([])
  const [whatIncluded, setWhatIncluded] = useState([])
  const [meetPoint, setMeetPoint] = useState({
    address: '',
    map_link: '',
    extra: '',
  })
  const [endPoint, setEndPoint] = useState({
    address: '',
    map_link: '',
    extra: '',
  })
  const [samePoint, setSamePoint] = useState(false)
  //Country and cities
  const sortedCities = Object.keys(Cities).reduce((acc, country) => {
    acc[country] = Cities[country].sort()
    return acc
  }, {})
  const [selectedCountry, setSelectedCountry] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [cities, setCities] = useState([])
  const handleCountryChange = (event) => {
    setSelectedCountry(event)
    setCities(sortedCities[event.label] || [])
  }
  ////////
  const handleChange = (file) => {
    setFile(Object.values(file))
    if (Object.values(file).length > 0) {
      const readers = Object.values(file).map((file) => {
        const reader = new FileReader()

        reader.onloadend = () => {
          const blob = new Blob([reader.result], { type: file.type })
          const blobURL = URL.createObjectURL(blob)

          // When the reading is completed, update the state with the Blob URL and file name
          setSelectedFiles((prevFiles) => [
            ...prevFiles,
            { blobURL: blobURL, name: file.name },
          ])
        }

        // Read each selected file as a data URL
        reader.readAsArrayBuffer(file)
      })
    }
  }
  const handleDelete = (index) => {
    const updatedFiles = [...selectedFiles]
    const updatedFiles2 = [...file]
    updatedFiles.splice(index, 1)
    updatedFiles2.splice(index, 1)
    setSelectedFiles(updatedFiles)
    setFile(updatedFiles2)
  }
  async function handleSubmit() {
    showLoader()
    const formData = new FormData()
    for (let i = 0; i < file.length; i++) {
      formData.append(`images[]`, file[i])
    }
    const request = axiosCalls(`upload-image`, 'post', formData)
    const res = await request
    if (res) {
      if (res.er) {
        Toast('error', res.er.message)
        return
      }
      const data = {
        name: name,
        adult_price: parseInt(adultPrice),
        child_price: parseInt(childPrice),
        images: res.data,
        main_image: res.data[0],
        description: description,
        overview: description,
        venue: venue,
        social_media_links: socialMedia,
        event_date: date,
        country: selectedCountry.label,
        city: selectedCity.label,
        duration: duration,
        additional_info: additionalInfo,
        what_to_expect: whatToExpect,
        what_included: whatIncluded,
        languages: languages,
        mobile_ticket: mobileTicket === 'true' ? true : false,
        start_time: time,
        currency: currency,
        experience_category_id: selectedParentCats.value,
        experience_sub_category_id: selectedSubCats.value,
        meet_point: {
          address: meetPoint.address,
          map_link: meetPoint.map_link,
          extra: meetPoint.extra,
        },
        end_point: {
          address: samePoint ? meetPoint.address : endPoint.address,
          map_link: samePoint ? meetPoint.map_link : endPoint.map_link,
          extra: samePoint ? meetPoint.extra : endPoint.extra,
        },
      }
      hideLoader()
      const response = await axiosCalls(`admin/create-experience`, 'post', data)
      if (response) {
        hideLoader()
        if (response.er) {
          Toast('error', response.er.message)
          return
        }
        Toast('success', 'Experience saved successfully.')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    }
  }
  async function handleEditSubmit() {
    showLoader()
    const formData = new FormData()
    for (let i = 0; i < file.length; i++) {
      formData.append(`images[]`, file[i])
    }
    const request = axiosCalls(`upload-image`, 'post', formData)
    const res = await request
    if (res) {
      if (res.er) {
        Toast('error', res.er.message)
        return
      }
      // const data = {
      //   name: name,
      //   adult_price: parseInt(adultPrice),
      //   child_price: parseInt(childPrice),
      //   images: res.data,
      //   main_image: res.data[0],
      //   description: description,
      //   overview: description,
      //   venue: venue,
      //   social_media_links: socialMedia.split(',').map((item) => item.trim()),
      //   event_date: date,
      //   country: selectedCountry.label,
      //   city: selectedCity.label,
      //   duration: duration,
      //   additional_information: additionalInfo,
      //   what_to_expect: whatToExpect,
      //   experience_category_id: selectedParentCats.value,
      //   experience_sub_category_id: selectedSubCats.value,
      // }
      const data = {
        name: name,
        adult_price: parseInt(adultPrice),
        child_price: parseInt(childPrice),
        images: res.data,
        main_image: res.data[0],
        description: description,
        overview: description,
        venue: venue,
        social_media_links: socialMedia,
        event_date: date,
        country: selectedCountry.label,
        city: selectedCity.label,
        duration: duration,
        additional_info: additionalInfo,
        what_to_expect: whatToExpect,
        what_included: whatIncluded,
        languages: languages,
        mobile_ticket: mobileTicket === 'true' ? true : false,
        start_time: time,
        currency: currency,
        experience_category_id: selectedParentCats.value,
        experience_sub_category_id: selectedSubCats.value,
        meet_point: {
          address: meetPoint.address,
          map_link: meetPoint.map_link,
          extra: meetPoint.extra,
        },
        end_point: {
          address: samePoint ? meetPoint.address : endPoint.address,
          map_link: samePoint ? meetPoint.map_link : endPoint.map_link,
          extra: samePoint ? meetPoint.extra : endPoint.extra,
        },
      }
      const response = await axiosCalls(
        `admin/update-experience/${selectedExperience.id}`,
        'post',
        data
      )
      if (response) {
        hideLoader()
        if (response.er) {
          Toast('error', response.er.message)
          return
        }
        Toast('success', 'Experience saved successfully.')
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      }
    }
  }

  /// Parent category
  const [parentCatLoading, setParentCatLoading] = useState(true)
  const [parentCatList, setParentCatList] = useState([])
  const [selectedParentCats, setSelectedParentCats] = useState({})
  const getParentCategories = async () => {
    setParentCatLoading(true)
    const res = await axiosCalls(`admin/experience-category`, 'get')
    if (res) {
      setParentCatLoading(false)
      if (res.er) {
        Toast('error', res.er.message)
        setParentCatList([])
        return
      }
      setParentCatList(res?.data ?? [])
      return
    }
    setParentCatList([])
  }
  useEffect(() => {
    getParentCategories()
  }, [])
  //Parent Sub category.
  const [subCatLoading, setSubCatLoading] = useState(false)
  const [subCatList, setSubCatList] = useState([])
  const [selectedSubCats, setSelectedSubCats] = useState({})
  const getSubCategories = async (value) => {
    setSubCatLoading(true)
    setSubCatList([])
    setSelectedSubCats({})
    const res = await axiosCalls(`admin/experience-sub-category`, 'get')

    if (res) {
      setSubCatLoading(false)

      if (res.er) {
        Toast('error', res.er.message)
        setSubCatList([])
        return
      }
      const filteredList = res.data.filter(
        (item) => item.experience_category_id === value
      )
      setSubCatList(filteredList ?? [])
      return
    }
    setSubCatList([])
  }
  /// this is for the edit.
  const imageUrlsToFiles = async (urls) => {
    const files = []
    for (const url of urls) {
      try {
        const file = await ImageUrl(url)
        files.push(file)
      } catch (error) {
        console.error(error)
      }
    }
    return files
  }
  useEffect(() => {
    if (selectedExperience) {
      setName(selectedExperience.name)
      setVenue(selectedExperience.venue)
      setAdultPrice(selectedExperience.adult_price)
      setChildPrice(selectedExperience.child_price)
      setDescription(selectedExperience.description)
      if (selectedExperience.social_media_links) {
        setSocialMedia(JSON.parse(selectedExperience.social_media_links))
      }
      if (selectedExperience.event_date) {
        setDate(selectedExperience.event_date)
      }
      if (selectedExperience.country) {
        setSelectedCountry({
          label: selectedExperience.country,
          value: selectedExperience.country,
        })
      }
      if (selectedExperience.city) {
        setSelectedCity({
          label: selectedExperience.city,
          value: selectedExperience.city,
        })
      }
      if (selectedExperience.duration) {
        setDuration(selectedExperience.duration)
      }
      if (selectedExperience.currency) {
        setCurrency(selectedExperience.currency)
      }

      setMobileTicket(selectedExperience.mobile_ticket)

      if (selectedExperience.start_time) {
        setTime(selectedExperience.start_time)
      }
      if (selectedExperience.what_to_expect) {
        setWhatToExpect(JSON.parse(selectedExperience.what_to_expect))
      }
      if (selectedExperience.what_included) {
        setWhatIncluded(JSON.parse(selectedExperience.what_included))
      }
      if (selectedExperience.additional_info) {
        setAdditionalInfo(JSON.parse(selectedExperience.additional_info))
      }
      if (selectedExperience.experience_category_id && !parentCatLoading) {
        const foundCategory = parentCatList.find(
          (item) => item.id === selectedExperience.experience_category_id
        )
        if (foundCategory) {
          setSelectedParentCats({
            label: foundCategory.name,
            value: foundCategory.id,
          })
        }
      }
      if (selectedExperience.experience_sub_category_id) {
        setSubCatLoading(true)
        getSubCategories(selectedExperience.experience_category_id)
        if (!subCatLoading) {
          const foundSub = subCatList.find(
            (item) => item.id === selectedExperience.experience_sub_category_id
          )
          if (foundSub) {
            setSelectedSubCats({
              label: foundSub.name,
              value: foundSub.id,
            })
          }
        }
      }
      const files = async () => {
        const file = await imageUrlsToFiles(
          JSON.parse(selectedExperience.images)
        )
        handleChange(file)
      }
      files()
    }
  }, [selectedExperience, parentCatLoading])

  return (
    <ModalLayout>
      <div className='adminOrders-headingFam'>
        <div className='adminOrders-headingNo'>Experiences</div>
        <div
          className='adminOrders-headingClose'
          onClick={handleToggleNewModal}
        >
          <img src={closeOrderModal} />
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'> Name</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='newProductForm-NameInputSub'>
          Give your product a short name
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Event Date</div>
        <div className='newProductForm-NameInput'>
          <input
            type='date'
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>
            Country <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-PareselectCate'>
            <Select
              name='colors'
              options={Object.keys(sortedCities)
                .sort()
                .map((country) => ({
                  label: country,
                  value: country,
                }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={handleCountryChange}
              value={selectedCountry}
            />
          </div>
        </div>

        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>
            City<span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-ChilselectCate'>
            <Select
              name='colors'
              options={cities.sort().map((item) => ({
                label: item,
                value: item,
              }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => setSelectedCity(e)}
              value={selectedCity}
            />
          </div>
        </div>
      </div>
      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>
            Parent category <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-PareselectCate'>
            <Select
              name='colors'
              options={parentCatList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => {
                setSelectedParentCats(e)
                getSubCategories(e.value)
              }}
              isLoading={parentCatLoading}
              value={selectedParentCats}
            />
          </div>
        </div>

        <div className='newProductForm-NameGen'>
          <div className='newProductForm-NameGenTag'>
            Parent subcategory <span className='add-product-required '>*</span>
          </div>
          <div className='newProductForm-ChilselectCate'>
            <Select
              name='colors'
              options={subCatList.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              className='basic-multi-select'
              classNamePrefix='select'
              onChange={(e) => setSelectedSubCats(e)}
              isLoading={subCatLoading}
              value={selectedSubCats}
            />
          </div>
        </div>
      </div>

      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Venue</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={venue}
            onChange={(e) => setVenue(e.target.value)}
          />
        </div>
      </div>
      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTag'>Duration</div>
          <div className='newProductForm-NameInput'>
            <select
              name=''
              id=''
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            >
              <option value=''>-select-</option>
              {DurationData.map((item, i) => (
                <option value={item} key={i}>
                  {item}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>Currency</div>
          <div className='newProductForm-NameInput'>
            <select
              name=''
              id=''
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value=''>-select-</option>
              {CurrencyData.map((item, i) => (
                <option value={item.short} key={i}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>Adult Price</div>
          <div className='newProductForm-PareNairCateOne'>
            <input
              type='number'
              value={parseInt(adultPrice)}
              onChange={(e) => setAdultPrice(e.target.value)}
            />
          </div>
        </div>

        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>Kid Price</div>
          <div className='newProductForm-PareNairCateOne'>
            <input
              type='number'
              value={childPrice}
              onChange={(e) => setChildPrice(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className='newProductForm-selectFlex'>
        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>
            Opening / Start time
          </div>
          <div className='newProductForm-PareNairCateOne'>
            <input
              type='time'
              value={time}
              onChange={(e) => setTime(e.target.value)}
            />
          </div>
        </div>

        <div className='newProductForm-NameGenOne'>
          <div className='newProductForm-NameGenTagOne'>
            Mobile Ticket Available?
          </div>
          <div className='newProductForm-PareNairCateOne'>
            <select
              name=''
              id=''
              value={mobileTicket}
              onChange={(e) => setMobileTicket(e.target.value)}
            >
              <option value=''>-select-</option>
              <option value={true}>True</option>
              <option value={false}>False</option>
            </select>
          </div>
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Event Description</div>
        <div className='newProductForm-NameInput'>
          <textarea
            name=''
            id=''
            cols='30'
            rows='10'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>
        </div>
        <div className='newProductForm-NameInputSub'>
          Give your event a short and clear description
        </div>
      </div>
      <InputToArray
        placeholder={'Type and press Enter'}
        tag={'Additional Info'}
        onItemsChange={(item) => {
          setAdditionalInfo(item)
        }}
        initialItems={additionalInfo}
      />
      <InputToArray
        placeholder={'Type and press Enter'}
        tag={' What should buyers expect?'}
        onItemsChange={(item) => {
          setWhatToExpect(item)
        }}
        initialItems={whatToExpect}
      />
      <InputToArray
        placeholder={'Type and press Enter'}
        tag={' Whats Included'}
        onItemsChange={(item) => {
          setWhatIncluded(item)
        }}
        initialItems={whatIncluded}
      />
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Meet Point Address</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={meetPoint.address}
            onChange={(e) =>
              setMeetPoint((prevMeetPoint) => ({
                ...prevMeetPoint,
                address: e.target.value,
              }))
            }
          />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Meet Point map link</div>
        <div className='newProductForm-NameInput'>
          <input
            type='url'
            value={meetPoint.map_link}
            onChange={(e) =>
              setMeetPoint((prevMeetPoint) => ({
                ...prevMeetPoint,
                map_link: e.target.value,
              }))
            }
          />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>
          Meet Point extra details
        </div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={meetPoint.extra}
            onChange={(e) =>
              setMeetPoint((prevMeetPoint) => ({
                ...prevMeetPoint,
                extra: e.target.value,
              }))
            }
          />
        </div>
      </div>
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>
          <input
            type='checkbox'
            value={samePoint}
            onChange={(e) => setSamePoint(e.target.checked)}
          />{' '}
          Endpoint is the same as meet point?
        </div>
      </div>
      {!samePoint && (
        <>
          <div className='newProductForm-NameGen'>
            <div className='newProductForm-NameGenTag'>End Point Address</div>
            <div className='newProductForm-NameInput'>
              <input
                type='text'
                value={endPoint.address}
                onChange={(e) =>
                  setEndPoint((prevMeetPoint) => ({
                    ...prevMeetPoint,
                    address: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className='newProductForm-NameGen'>
            <div className='newProductForm-NameGenTag'>End Point map link</div>
            <div className='newProductForm-NameInput'>
              <input
                type='url'
                value={endPoint.map_link}
                onChange={(e) =>
                  setEndPoint((prevMeetPoint) => ({
                    ...prevMeetPoint,
                    map_link: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div className='newProductForm-NameGen'>
            <div className='newProductForm-NameGenTag'>
              End Point extra details
            </div>
            <div className='newProductForm-NameInput'>
              <input
                type='text'
                value={endPoint.extra}
                onChange={(e) =>
                  setEndPoint((prevMeetPoint) => ({
                    ...prevMeetPoint,
                    extra: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </>
      )}
      <InputToArray
        placeholder={'Type and press Enter'}
        tag={'Event Social media Links'}
        onItemsChange={(item) => {
          setSocialMedia(item)
        }}
        initialItems={socialMedia}
      />
      <div className='newProductForm-NameGen'>
        <div className='newProductForm-NameGenTag'>Languages</div>
        <div className='newProductForm-NameInput'>
          <input
            type='text'
            value={languages}
            onChange={(e) => setLanguages(e.target.value)}
          />
        </div>
        <div className='newProductForm-NameInputSub'>
          Type different languages and separate with a comma (,).
        </div>
      </div>

      <div className='newProductForm-ProdImgText'>Product Images</div>

      <div className='newProductForm-fileUploader'>
        <FileUploader
          multiple={true}
          handleChange={handleChange}
          name='file'
          types={fileTypes}
          label='Drop your images here, or click to browse 1600 x 1200 recommend, up to 10MB each.'
        />
        {/* <p className='newProductForm-fileUploadName'>
          {file ? `File name: ${file[0].name}` : 'no files uploaded yet'}
        </p> */}
      </div>
      <div className='newProductForm-fileUploadText'>
        Add up to 8 images to your products. Used to represent your products
        during checkout, in email, social sharing and more.
      </div>

      <div className='newProductForm-fileFlexS'>
        <div className='newProductForm-fileFlexI'>Image</div>
        <div className='newProductForm-fileFlexM'>Select Main Image</div>
      </div>
      {file && (
        <>
          {selectedFiles.map((item, i) => (
            <div className='newProductForm-fileImgListGen' key={i + 1}>
              <div className='newProductForm-fileImgListFam'>
                <div className='newProductForm-fileImgMain'>
                  <img src={item.blobURL} />
                </div>
                <div className='newProductForm-fileImgName'>{item.name}</div>
              </div>

              <div className='newProductForm-fileImgDel'>
                <img src={productTrashIcon} onClick={() => handleDelete(i)} />
              </div>
            </div>
          ))}
        </>
      )}

      <div className='newProductForm-SaveGen'>
        {selectedExperience ? (
          <div
            className='newProductForm-SaveChanges'
            onClick={handleEditSubmit}
          >
            Save Edit
          </div>
        ) : (
          <div className='newProductForm-SaveChanges' onClick={handleSubmit}>
            Create
          </div>
        )}
      </div>
    </ModalLayout>
  )
}

export default NewProductModal

import Routes from './routes'
import React from 'react'

function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  )
}

export default App

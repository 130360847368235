import React, { useEffect, useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import Navbar from '../../components/layout/navbar'
import Footer from '../../components/layout/footer'
import eventsBanner from '../../assets/images/events.png'
import './style.scss'
import NewAdditions from '../../components/newAdditions/NewAdditions'
import { axiosCalls } from '../../_api'
import { CartContext } from '../../context/cart-context'
import InfoData from '../../helpers/data/InfoData'
import ContentLoader from 'react-content-loader'
import { Icon } from '@iconify/react'
import Cities from '../../assets/Data/cities'
import { Toast } from '../../helpers/toast'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'

const flattenData = (data) => {
  const flatArray = []
  for (const country in data) {
    data[country].forEach((city) => {
      flatArray.push({ country, city })
    })
  }
  return flatArray
}

const flatData = flattenData(Cities)

const Home = () => {
  const { productCategory, productCategoryLoading } = useContext(CartContext)
  const [newAdditions, setNewAdditions] = useState([])
  const [topSelling, setTopSelling] = useState([])
  const [loading1, setLoading1] = useState(true)
  const [loading2, setLoading2] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [auth, setAuth] = useState(false)
  const [eventLoading, setEventLoading] = useState(true)
  const [eventList, setEventList] = useState([])

  const navigate = useNavigate()
  useEffect(() => {
    getAdditions()
    getTopSelling()
    const token = localStorage.getItem('token')
    if (token === '' || token == null) {
      setAuth(false)
    } else {
      setAuth(true)
    }
  }, [auth, navigate])

  const getAllEvents = async () => {
    setEventLoading(true)
    const res = await axiosCalls('landing-page-experiences', 'get')
    if (res) {
      setEventLoading(false)
      if (res.er) {
        setEventLoading(false)
        return
      }
      setEventList(res?.data.data)
    }
    setTimeout(() => {
      setEventLoading(false)
    }, 2000)
  }
  useEffect(() => {
    getAllEvents()
  }, [])

  const getAdditions = async () => {
    setLoading1(true)
    const res = await axiosCalls('landing-page-new-additions', 'get')
    if (res) {
      setTimeout(() => {
        setLoading1(false)
      }, 2000)
      if (res.er) {
        return
      }
      setNewAdditions(res.data.data)
    }
    setTimeout(() => {
      setLoading1(false)
    }, 2000)
  }
  const getTopSelling = async () => {
    setLoading2(true)
    const res = await axiosCalls('landing-page-top-selling', 'get')
    if (res) {
      setTimeout(() => {
        setLoading2(false)
      }, 2000)
      if (res.er) {
        return
      }
      setTopSelling(res.data.data)
    }
    setTimeout(() => {
      setLoading2(false)
    }, 2000)
  }
  function findExperience() {
    if (inputValue.length < 1) {
      Toast('warn', 'please input a place')
    }
    navigate(
      `/search-experience?city=${selected.city}&country=${
        selected.country
      }&date=${format(new Date(date), 'yyyy-MM-dd')}`
    )
    // http://localhost:3000/search-experience/lagos/ii
  }

  // Dropdown  functionality
  const [suggestions, setSuggestions] = useState([])
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [date, setDate] = useState(new Date())
  const [selected, setSelected] = useState({})

  useEffect(() => {
    if (inputValue) {
      const inputWords = inputValue.toLowerCase().split(/[\s,]+/)
      const filteredSuggestions = flatData.filter((item) =>
        inputWords.every(
          (word) =>
            item.city.toLowerCase().includes(word) ||
            item.country.toLowerCase().includes(word)
        )
      )
      setSuggestions(filteredSuggestions)
      setShowSuggestions(true)
    } else {
      setShowSuggestions(false)
    }
  }, [inputValue])

  const handleInputChange = (event) => {
    setInputValue(event.target.value)
  }

  const handleSuggestionClick = (suggestion) => {
    setShowSuggestions(false)
    setSelected(suggestion)
    setInputValue(`${suggestion.city}, ${suggestion.country}`)
  }
  return (
    <div>
      <Navbar />
      <div
        style={{
          backgroundImage: `url(${eventsBanner})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          width: '100vw',
          height: '550px',
        }}
        className='home-banner-family-cont'
      >
        <div className='home-banner-text'>
          <h2>Check out Our Exciting Experiences and Events for Kids</h2>
          <div className='search-inputs'>
            <div className='inputs'>
              <div className='input-card'>
                <p>where to?</p>
                <input
                  type='text'
                  name='location'
                  id=''
                  placeholder='Search for a place or activity'
                  required
                  value={inputValue}
                  onChange={handleInputChange}
                />
                {showSuggestions && (
                  <div className='drop-down'>
                    <ul>
                      {suggestions.slice(0, 10).map((item, i) => (
                        <li
                          key={i + 1}
                          onClick={() => handleSuggestionClick(item)}
                        >
                          {item.city}, <b>{item.country}</b>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className='input-card'>
                <p>when</p>
                <DatePicker
                  selected={date}
                  onChange={(date) => setDate(date)}
                  placeholderText='heyy'
                  minDate={new Date()}
                  dateFormat='yyyy/mm/dd'
                />
              </div>
              <button onClick={findExperience}>
                <Icon icon='tabler:search' />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className='productWrap dif'>
        {!eventLoading && (
          <>
            {eventList.slice(0, 4).map((item, i) => (
              <div
                className='new-addition-sale-container'
                onClick={() => navigate(`/single-experience/${item.id}`)}
                key={item.id}
              >
                <div className='new-addition-love-icon'>
                  {/* <img src={loveIcon} alt='love_icon' /> */}
                </div>
                <div className='new-selling-product'>
                  <img
                    src={item.main_image}
                    onError={(e) => {
                      e.target.src =
                        'https://ik.imagekit.io/wya0h5u2s/Empty_Image_48QhXVCGQ.png'
                    }}
                    alt='new_product'
                  />
                </div>
                <div className='new-selling-product-name'>
                  <p>{item?.name}</p>
                </div>
                <div className='new-selling-product-price'>
                  <p>
                    ₦{item?.child_price.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  </p>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
      {/* <div className='home-info'>
        {InfoData.map((item) => (
          <div className='card' key={item.id}>
            <div className='left'>
              <div className='round'>
                <item.icon />
              </div>
            </div>
            <div className='right'>
              <h2>{item.tag}</h2>
              <p>{item.details}</p>
            </div>
          </div>
        ))}
      </div> */}
      <div className='kiddies-banner'>
        <div className='kiddies-info'>
          <div className='left'>
            <h2>Event Vendors & Centers</h2>
            <p>
              Need an Vendor, decorator and planner for your child’s upcoming
              event?
            </p>
          </div>
          <div className='right'>
            <button>Browse Vendors </button>
          </div>
        </div>
      </div>
      {/* <div className='categories'>
        <div className='categories-top'>
          <h2>Our Categories</h2>
        </div>
        {productCategoryLoading ? (
          <div
            style={{
              display: 'flex',
            }}
          >
            {['1', '2', '3', '4', 5].map((item, i) => (
              <div style={{ marginRight: '10px', width: '19%' }} key={i + 1}>
                <ContentLoader
                  speed={2}
                  width={'100%'}
                  height={250}
                  // viewBox="60 60 400 350"
                  backgroundColor=' #5e5d5d11'
                  foregroundColor='#2f2a293b'
                  // {...props}
                >
                  <rect width='100%' height='100%' />
                </ContentLoader>
              </div>
            ))}
          </div>
        ) : (
          <div className='rows'>
            {productCategory
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <div
                  className='card'
                  key={item.id}
                  onClick={() => {
                    navigate(`/product-category/${item.id}`)
                  }}
                >
                  <div className='img'>
                    <img src={item.thumbnail_image} alt='' />
                  </div>
                  <p>{item.name}</p>
                </div>
              ))}
          </div>
        )}
      </div>
      <div className='sales-section app-padding'>
        <NewAdditions
          headingTag='New Additions'
          data={newAdditions}
          loading={loading1}
        />
      </div>
      <div className='sales-section app-padding'>
        <NewAdditions
          headingTag='Top Selling Products'
          data={topSelling}
          loading={loading2}
        />
      </div> */}
      <Footer />
    </div>
  )
}

export default Home

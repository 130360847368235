import React, { useRef, useEffect, useState, useContext } from 'react'
import './style.scss'
import Footer from '../../components/layout/footer'
import Navbar from '../../components/layout/navbar'
import { Icon } from '@iconify/react'
import Ratings from '../../components/ratings/Ratings'
import Slider from '../../components/slider/Slider'
import SimilarExperiences from './components/SimilarExperiences'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { useNavigate, useParams } from 'react-router-dom'
import { axiosCalls } from '../../_api'
import Loader from '../../components/loader/Loader'
import { CartContext } from '../../context/cart-context'
import { getCurrencySignByShort } from '../../helpers/currencyUtilis'
import { Toast } from '../../helpers/toast'
import { hideLoader, showLoader } from '../../loader'

function NewSingleEvent() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [selectedImage, setSelectedImage] = useState('')
  const [included, setIncluded] = useState(false)
  const [meet, setMeet] = useState(false)
  const [expect, setExpect] = useState(false)
  const [additional, setAdditional] = useState(false)
  const [auth, setAuth] = useState(false)
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState({})
  const [similarLoading, setSimilarLoading] = useState(true)
  const [similarEvents, setSimilarEvents] = useState([])
  const reviews = {
    totalStars: 48,
    starCount: {
      5: 40,
      4: 0,
      3: 3,
      2: 5,
      1: 0,
    },
  }

  const [date, setDate] = useState(new Date())

  const containerRef = useRef(null)
  const [isSticky, setIsSticky] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const containerTop = containerRef.current.getBoundingClientRect().top
        const containerBottom =
          containerRef.current.getBoundingClientRect().bottom
        const stickyElementHeight = 60 // Adjust based on your sticky element height

        if (containerTop <= 0 && containerBottom >= stickyElementHeight) {
          setIsSticky(true)
        } else {
          setIsSticky(false)
        }
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const getProduct = async () => {
    const res = await axiosCalls(`single-experience/${id}`, 'get')
    if (res) {
      setLoading(false)
      if (res.er) {
        return
      }
      setEvent(res.data[0])
      setSelectedImage(JSON.parse(res.data[0].images)[0])
      setLoading(false)
    }
  }
  useEffect(() => {
    const token = localStorage.getItem('token')
    const tokenGuest = localStorage.getItem('bubble_guest_code')
    if (token || tokenGuest) {
      setAuth(true)
    } else {
      // navigate("/cart-page");
      setAuth(false)
    }
    getProduct()
  }, [])
  const getSimilarEvent = async () => {
    setSimilarLoading(true)
    const res = await axiosCalls(
      `experience-under-a-category/${event.experience_category_id}`,
      'get'
    )

    if (res) {
      if (res.er) {
        return
      }
      setSimilarEvents(res.data.filter((item) => item.id !== event.id))
      setSimilarLoading(false)
    }
  }
  useEffect(() => {
    if (!loading) {
      getSimilarEvent()
    }
  }, [loading])
  const convertTo12HourFormat = (time) => {
    let [hours, minutes] = time.split(':').map(Number)
    const amOrPm = hours >= 12 ? 'PM' : 'AM'
    hours = hours % 12 || 12 // Convert hour from 24-hour to 12-hour format
    return `${hours}:${minutes.toString().padStart(2, '0')} ${amOrPm}`
  }
  //Add to cart <------------------->
  const { fetchLocalExperienceCart, checkAuth, isLoggedIn } =
    useContext(CartContext)
  const [adultCount, setAdultCount] = useState(0)
  const [childCount, setChildCount] = useState(0)
  function totalPrice() {
    let adult = adultCount * event.adult_price
    let child = childCount * event.child_price
    const data = adult + child
    return data.toLocaleString()
  }
  useEffect(() => {
    checkAuth()
  }, [isLoggedIn])
  const addToCart = async () => {
    let newNum = childCount + adultCount
    if (newNum < 1) {
      Toast('error', 'Select at least 1 ticket to purchase.')
      return
    }
    const localData = {
      name: event.name,
      experience_id: event.id,
      user_type: 'user',
      no_of_adults: adultCount,
      no_of_children: childCount,
      adult_price: event.adult_price,
      child_price: event.child_price,
      image: event.main_image,
      description: event.description,
      duration: event.duration,
    }
    let experienceCart =
      JSON.parse(localStorage.getItem('experience_cart')) || []
    const existingExperienceIndex = experienceCart.findIndex(
      (item) => item.experience_id === event.id
    )
    if (existingExperienceIndex !== -1) {
      experienceCart[existingExperienceIndex].no_of_adults += adultCount
      experienceCart[existingExperienceIndex].no_of_children += childCount
    } else if (experienceCart.length < 1) {
      experienceCart.push(localData)
    } else {
      Toast('error', 'An experience already exists in your cart.')
    }
    localStorage.setItem('experience_cart', JSON.stringify(experienceCart))
    fetchLocalExperienceCart()
    const data = {
      experience_id: event.id,
      user_type: 'user',
      no_of_adults: adultCount,
      no_of_children: childCount,
    }
    if (auth) {
      showLoader()
      const res = await axiosCalls(`experience-cart`, 'post', data)
      if (res) {
        hideLoader()
        if (res.er) {
          if (res.er.status_code === '401') {
            Toast('error', 'Please login to continue.')
            setTimeout(() => {
              navigate('/login')
            }, 3000)
          } else {
            Toast('error', res.er.message)
          }
          return
        }
        Toast('success', 'Event added to cart successfully')
        navigate('/cart-page?tab=experience')
        fetchLocalExperienceCart()
      }
    } else {
      Toast('success', 'Event added to cart successfully')
      navigate('/cart-page?tab=experience')
    }
  }

  return loading ? (
    <Loader align={'center'} />
  ) : (
    <div className='new-single'>
      <Navbar />
      <div className='new-single-center'>
        <div className='wrap'>
          <div className='top flex justify-between'>
            <p className='direction'>
              Home / Things to do in Portugal / Things to do in Algarve / Things
              to do in Lagos / Lagos Tours / Wine Tastings
            </p>
            <div className='top-contacts'>
              <ul>
                <li className='flex items-center'>
                  <Icon icon={'ic:round-call'} />
                  <span>Book online or call: +1 (702) 648-5873</span>
                </li>
                <li className='flex items-center'>
                  <Icon icon={'bi:chat-text-fill'} />
                  <span>Chat now</span>
                </li>
              </ul>
            </div>
          </div>
          <div className='title'>
            <h1>{event.name}</h1>
          </div>
          <div className=' rates flex'>
            <div className='one flex items-center'>
              <Ratings average_rating={event.average_rating} />
              <span className='review-count'>
                {event.reviews.length} Review
              </span>
            </div>
            {/* <div className='two flex'>
              <p>Vendor Name</p>
            </div> */}
            <div className='three'>
              <p>
                {event.city}, {event.country}
              </p>
            </div>
          </div>
          <div
            className='main-details flex justify-between'
            ref={containerRef}
            style={{ minHeight: '800px' }}
          >
            <div className='left'>
              <div className='images flex'>
                <div className='one'>
                  {JSON.parse(event.images).map((item, i) => (
                    <div key={i + 1} onClick={() => setSelectedImage(item)}>
                      <img
                        src={item}
                        alt=''
                        className={item === selectedImage ? 'active' : ''}
                      />
                    </div>
                  ))}
                </div>
                <div className='two'>
                  <img src={selectedImage} alt='' />
                </div>
              </div>
              <div className='flex duration'>
                <div className='flex items-center'>
                  <Icon icon={'iconamoon:clock'} />
                  <span>{event.duration}</span>
                </div>
                {event.mobile_ticket && (
                  <div className='flex items-center'>
                    <Icon icon={'uil:mobile-android-alt'} />
                    <span>Mobile ticket</span>
                  </div>
                )}
                <div className='flex items-center'>
                  <Icon icon={'token:chat'} />
                  <span>Offered in: {event.languages}</span>
                </div>
              </div>
              {/* /// */}
              <div className='dateAndForm '>
                <div className='dateAnd'>
                  <div className='input-card'>
                    <DatePicker
                      selected={date}
                      onChange={(date) => setDate(date)}
                      placeholderText='heyy'
                      minDate={new Date()}
                      dateFormat='yyyy/mm/dd'
                      showIcon
                      icon={<Icon icon={'octicon:calendar-16'} />}
                    />
                  </div>
                  <div className='input-card'>
                    <p>
                      Adult (16 {'>'}) -{' '}
                      {getCurrencySignByShort(event.currency)}
                      {parseInt(event.adult_price)}
                    </p>
                    <div className='single-product-quantity-count-fam'>
                      <div
                        onClick={() =>
                          adultCount > 0
                            ? setAdultCount(adultCount - 1)
                            : setAdultCount(0)
                        }
                        className='single-product-quantity-minus'
                      >
                        -
                      </div>
                      <div className='single-product-quantity-show'>
                        <input type='text' value={adultCount} />
                      </div>
                      <div
                        onClick={() => setAdultCount(adultCount + 1)}
                        className='single-product-quantity-add'
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className='input-card'>
                    <p>
                      Children (0 - 15) -{' '}
                      {getCurrencySignByShort(event.currency)}
                      {parseInt(event.child_price)}
                    </p>
                    <div className='single-product-quantity-count-fam'>
                      <div
                        onClick={() =>
                          childCount > 0
                            ? setChildCount(childCount - 1)
                            : setChildCount(0)
                        }
                        className='single-product-quantity-minus'
                      >
                        -
                      </div>
                      <div className='single-product-quantity-show'>
                        <input type='text' value={childCount} />
                      </div>
                      <div
                        onClick={() => setChildCount(childCount + 1)}
                        className='single-product-quantity-add'
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className='total flex items- center'>
                    <p>Total Price: </p>
                    <h2>
                      {getCurrencySignByShort(event.currency)}
                      {totalPrice()}
                    </h2>
                  </div>
                  <div className='input-card'>
                    <button onClick={addToCart}>Add to cart</button>
                  </div>
                </div>
              </div>
              {/* ////// */}
              <div className='overview'>
                <h2>Overview</h2>
                <p>{event.overview}</p>
                {/* <ul>
                  <li className='flex items-center'>
                    <Icon icon={'radix-icons:dot-filled'} /> Head to a beautiful
                    lookout point for the sunset
                  </li>
                  <li className='flex items-center'>
                    <Icon icon={'radix-icons:dot-filled'} /> Tuck into a
                    delicious meal on the beach with drinks
                  </li>
                  <li className='flex items-center'>
                    <Icon icon={'radix-icons:dot-filled'} /> Discover the
                    beautiful landscapes of the west coast
                  </li>
                  <li className='flex items-center'>
                    <Icon icon={'radix-icons:dot-filled'} /> A small group means
                    more attention from your guide
                  </li>
                </ul> */}
              </div>
              <div className='accordion'>
                <div
                  className='accordion-head flex items-center justify-between'
                  onClick={() => setIncluded(!included)}
                >
                  <h2>What's Included</h2>
                  {included ? (
                    <Icon icon={'ph:caret-up-bold'} />
                  ) : (
                    <Icon icon={'ph:caret-down-bold'} />
                  )}
                </div>
                {included && (
                  <div className='accordion-body'>
                    <ul>
                      {JSON.parse(event.what_included).map((item, i) => (
                        <li className='flex' key={i}>
                          <Icon icon={'mingcute:check-fill'} />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className='accordion'>
                <div
                  className='accordion-head flex items-center justify-between'
                  onClick={() => setMeet(!meet)}
                >
                  <h2>Meeting and Pickup</h2>
                  {meet ? (
                    <Icon icon={'ph:caret-up-bold'} />
                  ) : (
                    <Icon icon={'ph:caret-down-bold'} />
                  )}
                </div>
                {meet && (
                  <div className='accordion-body'>
                    <div className='flex meet-card'>
                      <div className='one'>
                        <div className='flex items-center'>
                          <Icon icon={'ion:location'} />
                          <h3>Meeting point</h3>
                        </div>
                        <p>{JSON.parse(event.meet_point).address}</p>
                        <a href={JSON.parse(event.meet_point).map_link}>
                          <p>Open in Google Maps</p>
                        </a>
                        <p className='more-meet'>
                          {JSON.parse(event.meet_point).extra}
                        </p>
                      </div>
                      <div className='two'>
                        <div className='flex items-center'>
                          <Icon icon={'hugeicons:racing-flag'} />
                          <h3>End point</h3>
                        </div>
                        {JSON.parse(event.meet_point).address ===
                        JSON.parse(event.end_point).address ? (
                          <p>This activity ends back at the meeting point.</p>
                        ) : (
                          <>
                            <div className='flex items-center'>
                              <Icon icon={'ion:location'} />
                              <h3>Meeting point</h3>
                            </div>
                            <p>{JSON.parse(event.end_point).address}</p>
                            <a href={JSON.parse(event.end_point).map_link}>
                              <p>Open in Google Maps</p>
                            </a>
                            <p className='more-meet'>
                              {JSON.parse(event.end_point).extra}
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                    <div className='start-time flex items-center'>
                      <Icon icon={'clarity:clock-line'} /> <b>Start time</b>{' '}
                      <span> :- {convertTo12HourFormat(event.start_time)}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='accordion'>
                <div
                  className='accordion-head flex items-center justify-between'
                  onClick={() => setExpect(!expect)}
                >
                  <h2>What To Expect</h2>
                  {expect ? (
                    <Icon icon={'ph:caret-up-bold'} />
                  ) : (
                    <Icon icon={'ph:caret-down-bold'} />
                  )}
                </div>
                {expect && (
                  <div className='accordion-body'>
                    <ul>
                      {JSON.parse(event.what_to_expect).map((item, i) => (
                        <li className='flex' key={i}>
                          <Icon icon={'mingcute:check-fill'} />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className='accordion'>
                <div
                  className='accordion-head flex items-center justify-between'
                  onClick={() => setAdditional(!additional)}
                >
                  <h2>Additional Info</h2>
                  {additional ? (
                    <Icon icon={'ph:caret-up-bold'} />
                  ) : (
                    <Icon icon={'ph:caret-down-bold'} />
                  )}
                </div>
                {additional && (
                  <div className='accordion-body'>
                    <ul>
                      {JSON.parse(event.additional_info).map((item, i) => (
                        <li className='flex' key={i}>
                          <Icon icon={'mingcute:check-fill'} />
                          <span>{item}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <div className='policy flex justify-between'>
                <div className='one'>
                  <h2>Cancellation Policy</h2>
                  <p>
                    You can cancel up to 24 hours in advance of the experience
                    for a full refund.
                  </p>
                </div>
                <div className='two'>
                  <h2>Questions?</h2>
                  <p>Visit the Viator Help Centre for any further questions.</p>
                  <p>Product code: 120441P3</p>
                  <button>Bubble Help Center</button>
                </div>
              </div>
              {/* {event.travelers_images && (
                <div className='photos'>
                  <h2>Traveler Photos</h2>
                  <div className='grid-photos'>
                    {image.map((item, i) => (
                      <div key={i + 1} className='img-cont'>
                        <img
                          src={item}
                          alt=''
                          className={item === selectedImage ? 'active' : ''}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )} */}
              {/* <div className='reviews'>
                <h2>Reviews</h2>
                <div className='product-rating flex'>
                  <div className='one'>
                    <div className='flex items-center'>
                      <h1>5.0</h1> <Ratings average_rating={5} />
                    </div>
                    <p>233 reviews</p>
                  </div>
                  <div className='two'>
                    <p>
                      Total review count and overall rating based on Bubble
                      colony reviews
                    </p>
                    {starLevels.map((level) => (
                      <div key={level} className='star-level'>
                        <span className='star-label'>{level} stars</span>
                        <div className='bar-container'>
                          <div
                            className='bar'
                            style={{
                              width: `${
                                (reviews.starCount[level] /
                                  reviews.totalStars) *
                                100
                              }%`,
                            }}
                          ></div>
                        </div>
                        <span className='star-count'>
                          {reviews.starCount[level]}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='comments'>
                  <div className='comments-top'>
                    <h3>Reviews by Bubble travelers.</h3>
                    <p>Showing 1-10 of 223 reviews</p>
                  </div>
                  <div className='comments-wrap'>
                    <div className='comments-card'>
                      <div className='flex rates'>
                        <Ratings average_rating={3} />
                        <h4>Wonderful</h4>
                      </div>
                      <p className='date'>Jill_P, Jun 2024</p>
                      <p className='comments-word'>
                        Fabulous trip! I don't know how they spotted the
                        dolphins, but they did. Everything i hoped for. Really
                        friendly staff running the trip.
                      </p>
                      <div className='image-container flex justify-between'>
                        {image.map((item, i) => (
                          <img src={item} key={i} alt='' />
                        ))}
                      </div>
                    </div>
                    <div className='comments-card'>
                      <div className='flex rates'>
                        <Ratings average_rating={3} />
                        <h4>Best dolphin watching tour I have ever seen!</h4>
                      </div>
                      <p className='date'>Anthony_N, Jun 2024</p>
                      <p className='comments-word'>
                        This was such a fun tour. There is something so special
                        about seeing dolphins in their natural environment. Kit
                        and John did a great job finding the dolphins. The
                        conditions were perfect and the boat felt very safe. We
                        are a family of 5 and we all loved this tour. Highly
                        recommend!!! Read more about Dolphin Watching from Lagos
                      </p>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <div className='right'>
              <div
                className={
                  isSticky ? 'sticky-element booking-card' : 'booking-card'
                }
              >
                <h2>
                  {' '}
                  From {getCurrencySignByShort(event.currency)}
                  {parseInt(event.adult_price) > parseInt(event.child_price)
                    ? parseInt(event.child_price)
                    : parseInt(event.adult_price)}
                </h2>
                <div className='dateAnd'>
                  <div className='input-card'>
                    <DatePicker
                      selected={date}
                      onChange={(date) => setDate(date)}
                      placeholderText='heyy'
                      minDate={new Date()}
                      dateFormat='yyyy/mm/dd'
                      showIcon
                      icon={<Icon icon={'octicon:calendar-16'} />}
                    />
                  </div>
                  <div className='input-card'>
                    <p>
                      Adult (16 {'>'}) -{' '}
                      {getCurrencySignByShort(event.currency)}
                      {parseInt(event.adult_price)}
                    </p>
                    <div className='single-product-quantity-count-fam'>
                      <div
                        onClick={() =>
                          adultCount > 0
                            ? setAdultCount(adultCount - 1)
                            : setAdultCount(0)
                        }
                        className='single-product-quantity-minus'
                      >
                        -
                      </div>
                      <div className='single-product-quantity-show'>
                        <input type='text' value={adultCount} />
                      </div>
                      <div
                        onClick={() => setAdultCount(adultCount + 1)}
                        className='single-product-quantity-add'
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className='input-card'>
                    <p>
                      Children (0 - 15) -{' '}
                      {getCurrencySignByShort(event.currency)}
                      {parseInt(event.child_price)}
                    </p>
                    <div className='single-product-quantity-count-fam'>
                      <div
                        onClick={() =>
                          childCount > 0
                            ? setChildCount(childCount - 1)
                            : setChildCount(0)
                        }
                        className='single-product-quantity-minus'
                      >
                        -
                      </div>
                      <div className='single-product-quantity-show'>
                        <input type='text' value={childCount} />
                      </div>
                      <div
                        onClick={() => setChildCount(childCount + 1)}
                        className='single-product-quantity-add'
                      >
                        +
                      </div>
                    </div>
                  </div>
                  <div className='total flex items- center'>
                    <p>Total Price: </p>
                    <h2>
                      {getCurrencySignByShort(event.currency)}
                      {totalPrice()}
                    </h2>
                  </div>
                  <div className='input-card'>
                    <button onClick={addToCart}>Add to cart</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='compare'>
            <h2>Compare Similar Experiences</h2>
            <div className='flex'>
              <div className='left'>
                <div className='single-card'>
                  <div className='card-top'>
                    <h3>CURRENT</h3>
                  </div>
                  <div className='card-rest'>
                    <img src={event.main_image} alt='' />
                    <h4>{event.name}</h4>
                    <div className='rows flex'>
                      <Ratings average_rating={event.average_rating} />{' '}
                      <span className='number'>{event.reviews.length}</span>
                    </div>
                    <div className='rows'>
                      <p>{event.duration}</p>
                    </div>
                    <div className='rows'>
                      <p>Free Cancellation</p>
                    </div>
                    <div className='rows'>
                      <h3>
                        From {getCurrencySignByShort(event.currency)}
                        {parseInt(event.adult_price) >
                        parseInt(event.child_price)
                          ? parseInt(event.child_price)
                          : parseInt(event.adult_price)}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className='right'>
                {similarLoading ? (
                  <Loader direction={'middle'} size={'small'} />
                ) : (
                  <Slider slides={SimilarExperiences(similarEvents)} />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default NewSingleEvent

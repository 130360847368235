import React from 'react'

const FormatNumber = ({ value }) => {
  let formattedValue = parseFloat(value)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  return <span>₦{formattedValue}</span>
}

export default FormatNumber
